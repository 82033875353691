<template>
  <div class="mb-4 mt-2 flex-col items-center justify-center">
    <!-- Moderation Policies -->
    <div class="bg-white rounded-lg shadow-lg w-[80%] p-8">
      <h3 class="text-2xl font-bold text-left mb-4 text-gray-800">Moderation Policies</h3>

      <ul class="text-left text-gray-700 list-disc list-inside space-y-2">
        <li>1. General Guidelines</li>
        <li>1.1 Respectful Communication: Users must communicate respectfully and avoid language that is offensive, inflammatory, or discriminatory.</li>
        <li>1.2 Relevant Content: Public itineraries, comments, and discussions should stay relevant to the platform’s purpose of planning and sharing itineraries.</li>
        <li>1.3 Accuracy of Information: Users are encouraged to provide accurate and up-to-date information in their itineraries to ensure reliability for others.</li>
        <li>2. Prohibited Content</li>
        <li>2.1 Hate Speech and Harassment: Content that promotes hate, violence, or harassment against individuals or groups based on race, ethnicity, religion, gender, sexual orientation, disability, or any other characteristic is strictly prohibited.</li>
        <li>2.2 Illegal Activities: Content that promotes or involves illegal activities, such as drug use, theft, or violence, is not allowed.</li>
        <li>2.3 Spam and Misuse: Posting spam, irrelevant advertisements, or excessive self-promotion is not permitted.</li>
        <li>2.4 Malicious Content: Users are prohibited from sharing content that contains viruses, malware, or other harmful software.</li>
        <li>2.5 Explicit Material: Content that is pornographic, excessively violent, or otherwise explicit is not allowed on the platform.</li>
        <li>2.6 Impersonation: Creating accounts to impersonate individuals, brands, or organizations is prohibited.</li>
        <li>2.7 Plagiarism: Users must not share itineraries or other content that infringes on copyright or intellectual property rights.</li>
        <li>3. Moderation Mechanisms</li>
        <li>3.1 Content Monitoring: Moderators will review user-generated content for compliance with these policies.</li>
        <li>3.2 Reporting Mechanism: Users can report inappropriate content or behavior using the platform’s reporting tools. Reports will be reviewed promptly.</li>
        <li>3.3 Warnings: For minor infractions, users will receive a warning along with educational information about the violated policy.</li>
        <li>3.4 Account Suspension or Ban: Repeated or severe violations may result in temporary suspension or permanent banning of a user’s account.</li>
        <li>Violation of moderation policies will result in the removal of the offending content.</li>
        <li>4. User Agreement: Continued use of the platform constitutes agreement with the updated policies.</li>
        <li>5. Enforcement Statement: GoAgenda reserves the right to remove any content or take action against accounts that violate these policies. These policies are designed to foster a respectful and enjoyable environment for all users.</li>
      </ul>
    </div>

    <!-- Privacy Policies -->
    <div class="bg-white rounded-lg shadow-lg w-[80%] p-8 mt-8">
      <h3 class="text-2xl font-bold text-left mb-4 text-gray-800">Privacy Policies</h3>

      <ul class="text-left text-gray-700 list-disc list-inside space-y-2">
        <li>Please check our Privacy Policies on this link: <a href="https://www.privacypolicies.com/live/287ad8f0-9a3b-47c5-9bce-8d71f0e37e9c" target="_blank" rel="noopener noreferrer">https://www.privacypolicies.com/live/287ad8f0-9a3b-47c5-9bce-8d71f0e37e9c</a></li>
      </ul>
    </div>

    <!-- Terms and Conditions -->
    <div class="bg-white rounded-lg shadow-lg w-[80%] p-8 mt-8">
      <h3 class="text-2xl font-bold text-left mb-4 text-gray-800">Terms and Conditions</h3>

      <ul class="text-left text-gray-700 list-disc list-inside space-y-2">
        <li>When using the Service, you agree not to:</li>
        <li>Upload content that is illegal, defamatory, infringing, or harmful.</li>
        <li>Impersonate another person or misrepresent your identity.</li>
        <li>Use the Service to harass, abuse, or harm others.</li>
        <li>If we determine that your use of the Service violates these terms, we reserve the right to terminate or suspend your account.</li>

        <li>5. Copyright and DMCA Policy</li>
        <li>If you believe that your copyright-protected material has been infringed on GoAgenda, please send a notice to our designated email at seasonquest.mail@gmail.com with the following details:</li>
        <li>A description of the copyrighted work you claim has been infringed.</li>
        <li>The URL or location on our Website of the alleged infringing material.</li>
        <li>Your contact information (name, email address, and phone number).</li>
        <li>A statement that you believe, in good faith, that the use of the material is unauthorized.</li>
        <li>Your physical or electronic signature.</li>

        <li>6. Intellectual Property</li>
        <li>GoAgenda does not claim exclusive ownership over any logo or branding material used on the Website. However, any content created by GoAgenda (excluding User Content) remains the property of GoAgenda and may not be used or distributed without prior permission.</li>

        <li>7. Feedback and Suggestions</li>
        <li>If you provide feedback or suggestions about the Service, you agree that GoAgenda can use this feedback without compensation or credit.</li>

        <li>8. Disclaimer of Warranties</li>
        <li>The Service is provided "as is" and "as available." GoAgenda makes no warranties, express or implied, regarding the accuracy, reliability, or availability of the Service.</li>

        <li>9. Limitation of Liability</li>
        <li>To the fullest extent permitted by law, GoAgenda will not be liable for any damages resulting from your use of or inability to use the Service.</li>

        <li>10. Governing Law</li>
        <li>These Terms of Service are governed by the laws of the Republic of the Philippines. Any disputes or claims arising from these terms will be subject to the exclusive jurisdiction of the courts located in the Philippines.</li>

        <li>11. Changes to the Terms of Service</li>
        <li>We reserve the right to modify these Terms of Service at any time. If changes are made, we will notify you by posting the updated terms on this page. Your continued use of the Service after the changes constitutes your acceptance of the updated terms.</li>

        <li>12. Contact Us</li>
        <li>If you have any questions about these Terms of Service, please contact us at: Email: seasonquest.mail@gmail.com</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutPolicies',
};
</script>