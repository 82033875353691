<template>
  <div class="about-us mt-20 p-8 bg-white shadow-lg rounded-lg">
    <h1 class="text-3xl font-bold mb-4">About Us</h1>
    <p class="text-lg mb-2">Welcome to GoAgenda!</p>
    <p class="mb-2">At GoAgenda, we are passionate about helping you create and share itineraries that inspire and connect people. Our platform is designed to make travel planning easy and enjoyable.</p>
    <p>Our team is dedicated to providing the best tools and resources for travelers around the world. Join us in exploring new destinations and sharing your adventures.</p>
    <p class="mt-4">Contact: <a href="mailto:seasonquest.mail@gmail.com" class="text-blue-500">seasonquest.mail@gmail.com</a></p>
  </div>
</template>

<script>
export default {
  name: 'AboutUs',
};
</script>

<style scoped>

</style>