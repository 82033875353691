<template>
  <div v-if="isLoading" class="loading-screen">
    <div class="spinner"></div>
  </div>
</template>

<script>
import { useLoading } from '../../context/UserContext';

export default {
  name: 'LoadingScreen',
  setup() {
    const loadingState = useLoading();
    return { isLoading: loadingState.isLoading };
  },
};
</script>

<style scoped>
.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.spinner {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
